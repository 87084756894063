import classNames from "classnames";
import React, { ReactElement } from "react";

import { addEditAttributes } from "utils/episerver";

import { Link, StyledIcon } from "./Document.styled";
import DocumentProps from "./DocumentProps";

const Document = ({
    linkText,
    url,
    target = "_blank",
    className,
    children,
}: DocumentProps): ReactElement => {
    const classes = classNames(className, "document");
    return (
        <Link
            className={classes}
            to={url}
            exact
            target={target}
            hoverSpan={true}
            {...addEditAttributes("Text")}
        >
            <StyledIcon icon="document28" />
            <span>{linkText || children || ""}</span>
        </Link>
    );
};

export default React.memo(Document);
