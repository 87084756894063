import { styled } from "styled-components";

import Icon from "components/Icon";
import { default as NavLink } from "components/Link";

export const Link = styled(NavLink)`
    vertical-align: baseline;
`;

export const StyledIcon = styled(Icon)`
    && {
        margin-right: 0.4375rem;
    }
`;
